/* tslint:disable */
/* eslint-disable */
/**
 * AutoMl Application API
 * Documentation of API endpoints of AutoMl Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FileDirectUploadLocalOutput
 */
export interface FileDirectUploadLocalOutput {
    /**
     *
     * @type {boolean}
     * @memberof FileDirectUploadLocalOutput
     */
    ok: boolean;
    /**
     *
     * @type {string}
     * @memberof FileDirectUploadLocalOutput
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof FileDirectUploadLocalOutput
     */
    imageFileId: number | null;
    /**
     *
     * @type {number}
     * @memberof FileDirectUploadLocalOutput
     */
    datasetId: number | null;
}

/**
 * Check if a given object implements the FileDirectUploadLocalOutput interface.
 */
export function instanceOfFileDirectUploadLocalOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ok" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "imageFileId" in value;
    isInstance = isInstance && "datasetId" in value;

    return isInstance;
}

export function FileDirectUploadLocalOutputFromJSON(json: any): FileDirectUploadLocalOutput {
    return FileDirectUploadLocalOutputFromJSONTyped(json, false);
}

export function FileDirectUploadLocalOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileDirectUploadLocalOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'ok': json['ok'],
        'name': json['name'],
        'imageFileId': json['image_file_id'],
        'datasetId': json['dataset_id'],
    };
}

export function FileDirectUploadLocalOutputToJSON(value?: FileDirectUploadLocalOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'ok': value.ok,
        'name': value.name,
        'image_file_id': value.imageFileId,
        'dataset_id': value.datasetId,
    };
}
