/* tslint:disable */
/* eslint-disable */
/**
 * AutoMl Application API
 * Documentation of API endpoints of AutoMl Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FileDirectUploadStartOutput
 */
export interface FileDirectUploadStartOutput {
    /**
     *
     * @type {number}
     * @memberof FileDirectUploadStartOutput
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof FileDirectUploadStartOutput
     */
    url: string;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof FileDirectUploadStartOutput
     */
    fields: { [key: string]: any; } | null;
    /**
     *
     * @type {string}
     * @memberof FileDirectUploadStartOutput
     */
    method: string;
}

/**
 * Check if a given object implements the FileDirectUploadStartOutput interface.
 */
export function instanceOfFileDirectUploadStartOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "fields" in value;
    isInstance = isInstance && "method" in value;

    return isInstance;
}

export function FileDirectUploadStartOutputFromJSON(json: any): FileDirectUploadStartOutput {
    return FileDirectUploadStartOutputFromJSONTyped(json, false);
}

export function FileDirectUploadStartOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileDirectUploadStartOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': json['id'],
        'url': json['url'],
        'fields': json['fields'],
        'method': json['method'],
    };
}

export function FileDirectUploadStartOutputToJSON(value?: FileDirectUploadStartOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'id': value.id,
        'url': value.url,
        'fields': value.fields,
        'method': value.method,
    };
}
