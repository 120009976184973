import {
    ApiUploadLocalCreateRequest,
    ApiUploadStartCreateRequest,
    Configuration,
    FileDirectUploadFinishInput,
    FileDirectUploadStartOutput,
    FilesApi
} from "../api-client";
import Cookies from 'js-cookie';

interface UploadData {
    file: File
    uploadRequest: ApiUploadLocalCreateRequest
    startRequest: ApiUploadStartCreateRequest
}

export function initUpload() {

    const app_domain: string = JSON.parse(document.getElementById('app_domain')?.textContent || "");
    const apiClient = new FilesApi(new Configuration({
        basePath: app_domain,
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken') || "",
        }
    }));

    async function uploadFile(uploadData: FileDirectUploadStartOutput, file: File) {
        const formData = new FormData();
        const uploadHeaders = new Headers();

        if (uploadData.fields) {
            // handle aws related fields
            for (const key in uploadData.fields) {
                formData.append(key, uploadData.fields[key]);
            }

        } else {
            // no aws upload -> django authentication
            uploadHeaders.append('X-CSRFToken', Cookies.get('csrftoken') || "");
        }

        let body: File | FormData;
        formData.append('file', file); // 'file' is the name of the field where the file will be uploaded
        if (uploadData.method === "PUT") {
            uploadHeaders.append('Content-Type', file.type);
            body = file;
        } else {
            formData.append('file', file);
            body = formData;
        }
        return await fetch(uploadData.url, {
            method: uploadData.method,
            headers: uploadHeaders,
            body: body,
        });
    }

    function increaseProgress(increase: number, length: number) {
        const upload_counter = document.getElementById("upload-counter");
        const progressBar = document.getElementById('progress-bar');

        if (upload_counter) {
            let state = parseFloat(upload_counter.innerText);
            state = (state + increase);
            upload_counter.innerText = state.toString();

            const progress = (state / length) * 100;
            if (progressBar) {
                progressBar.style.width = `${progress}%`;
            }

            // function gets 3 times called per index
        }
    }

    async function doUpload(fileData: UploadData, pos: number, length: number): Promise<void | FileDirectUploadFinishInput> {
        const maxRetries = 3; // Set the maximum number of retry attempts
        const retryDelay = 2000; // Delay between retries in milliseconds

        async function retryableOperation(operation: () => Promise<any>, operationName: string) {
            let attempt = 0;
            while (attempt < maxRetries) {
                try {
                    return await operation();
                } catch (e) {
                    attempt++;
                    console.error(`${operationName} failed (attempt ${attempt} of ${maxRetries})`, e);
                    if (attempt < maxRetries) {
                        await new Promise(resolve => setTimeout(resolve, retryDelay));
                    } else {
                        throw new Error(`${operationName} failed after ${maxRetries} attempts`);
                    }
                }
            }
        }

        try {
            // Start request
            const startResult = await retryableOperation(
                () => apiClient.apiUploadStartCreate(fileData.startRequest),
                "Start Request"
            );
            increaseProgress(1 / 3, length);

            const fileId: number = startResult.id as number;

            // Upload file
            const uploadResult = await retryableOperation(
                () => uploadFile(startResult, fileData.file),
                "File Upload"
            );
            increaseProgress(1 / 3, length);

            if (uploadResult && uploadResult.ok) {
                // Finish request
                const finishResult = await retryableOperation(
                    () => apiClient.apiUploadFinishCreate({"id": fileId.toString()}),
                    "Finish Request"
                );
                increaseProgress(1 / 3, length);

                if (finishResult.id) {
                    return uploadResult;
                }
            } else {
                const errorMessage = await uploadResult.text();
                console.error('Error uploading file. Status:', uploadResult.status, 'Message:', errorMessage);
            }
        } catch (e) {
            console.error("Upload process failed", e);
        }
    }

    const dataset_id: string = JSON.parse(document.getElementById('dataset_id')?.textContent || "");

    const form: HTMLFormElement | null = document.getElementById('upload') as HTMLFormElement;

    function isValidFileType(type: string) {
        // Add your allowed file types here, e.g., 'image/jpeg', 'application/pdf'
        const allowedTypes = ['image/jpeg', 'image/bmp', 'image/png'];
        return allowedTypes.includes(type);
    }

    function setAlert(fileInput: HTMLInputElement) {

        if (fileInput) {
            const span = document.createElement("span");
            span.classList.add("invalid-feedback");
            span.id = "error_1_id_file_field";
            span.innerText = "Falscher Dateityp, derzeit werden nur .jpg, .png und .bmp Dateien unterstützt";
            fileInput.classList.add("is-invalid");
            fileInput.parentElement?.appendChild(span);
        }
    }

    function unsetAlert(fileInput: HTMLInputElement) {

        if (fileInput) {
            fileInput.classList.remove("is-invalid");
            const span = document.getElementById("error_1_id_file_field");
            if (span) {
                fileInput.parentElement?.removeChild(span);
            }
        }
    }

    if (form) {

        //modify file counter on Change and evaluate filetype
        form.addEventListener('change', function (event) {
            event.preventDefault();
            const formData = new FormData(form);
            const files: FormDataEntryValue[] = formData.getAll("file_field");
            let isValid: boolean = true;

            for (let i = 0; i < files.length; i++) {
                const f: File = files[i] as File;
                const fileType: string = f.type;
                if (!isValidFileType(fileType)) {
                    isValid = false;
                    break;
                }
            }

            const fileInput: HTMLInputElement | null = document.getElementById('id_file_field') as HTMLInputElement;
            if (fileInput) {
                if (!isValid) {
                    fileInput.value = "";
                    setAlert(fileInput);

                } else {
                    unsetAlert(fileInput);
                }
            }


            const fileCounter = document.getElementById("file-counter");
            if (fileCounter) {
                fileCounter.innerText = files.length.toString();
            }
        });

        // Upload routine
        form.addEventListener('submit', async function (event) {
            event.preventDefault();

            const formData = new FormData(form);

            const files = formData.getAll("file_field");
            //const nFiles = files.length;


            // generate list of data necessary for each file upload
            const uploadData: UploadData[] = [];

            for (const file of files) {
                if (file instanceof File) {
                    uploadData.push({
                        //id unknown here, is known from start response later
                        "file": file,
                        "uploadRequest": {"fileId": "null", "file": file as Blob},
                        "startRequest": {"origFilename": file.name as string, "dataset": +dataset_id as number}
                    });
                }
            }
            // Hide form and show progress bar
            form.style.display = "none";
            const progressContainer: HTMLElement | null = document.getElementById('progress-container');
            progressContainer?.classList.remove("visually-hidden");

            const upload_counter = document.getElementById("upload-counter");

            if (upload_counter) {
                upload_counter.innerText = "0";
            }


            // send single file requests by mapping diUpload promise to uploadData and execute them by Promise.all in parallel
            const length: number = uploadData.length;

            const cancelButton: HTMLElement | null = document.getElementById('cancel');
            cancelButton?.classList.add("visually-hidden");
            const batchSize = 5;
            for (let i = 0; i < length; i += batchSize) {
                const batch = uploadData.slice(i, i + batchSize);
                await Promise.all(
                    batch.map((fileData, index) => doUpload(fileData, index + i, length))
                );
            }


            // show back button when upload finished
            const backButton: HTMLElement | null = document.getElementById('back');
            const loading: HTMLElement | null = document.getElementById('uploading-files');
            backButton?.classList.remove("visually-hidden");
            if (loading) {
                loading.textContent = "Finished";
            }

        });
    }
}
